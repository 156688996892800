import {Divider} from "antd";

export const releaseVersion = '2.0.14e2'
export const releaseNotes = (
    <>
        <h1>v2.0.14e2</h1>
        Release: 06.09.2024

        <h2>Updates</h2>
        <ul>
            <li>Behebung eines Fehler, durch welchen die erste Untersuchung bei einem Re-Upload von Daten dupliziert wurde. Dieser Fehler kann Rückwirkend behoben werden, indem überschriebene Uploads in der Uploadübersicht gelöscht werden. Diese Uploads sind daran zu erkennen, dass sie nur eine oder zwei Untersuchungen enthalten und die Zeitspanne nur das Anfangsdatum des überschreibenden Uploads umfasst.</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.14e</h1>
        Release: 11.08.2024

        <h2>Updates</h2>
        <ul>
            <li>Behebung eines Fehler, welcher das Anzeigen der Tabelle im RF Jahresbericht verhindert</li>
            <li>Behebung von Fehlern im TQM Import</li>
            <li>Korrektur von Schreibfehlern in den Berichten</li>
            <li>Korrektur der Achsenbeschriftung in Plots</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.14d2</h1>
        Release: 10.08.2024

        <h2>Updates</h2>
        <ul>
            <li>Behebung eines Fehler, welcher das Anzeigen von Bildern im Browser verhindert</li>
            <li>Behebung von Fehlern durch in Bildern durch fehlende Zuordnungen</li>
            <li>Verbesserung der Ansicht der AppendixTabelle auf kleinen Bildschirmen</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.14d</h1>
        Release: 09.08.2024

        <h2>Neu</h2>
        <ul>
            <li>Button zum Löschen von DRW-Mappings</li>
            <li>Automatische Auflösung der Spalte "DRW" in Mappingdaten zum richtigen DRW des Gerätetyps</li>
            <li>JahresberichtV2 für CT, MG, RF und XR</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Behebung eines Fehler, welcher das Laden der Plots im MonatsberichtV2 für RF verhindert hat</li>
            <li>Behebung des "Reindexing"-Fehlers im Import</li>
            <li>Behebung von Fehlern durch duplizierte Header am Ende der Datei in TQM</li>
            <li>Anpassungen von Textformatierungen in den Berichten</li>
            <li>Ergänzung des Hinweis zu meldepflichtigen Vorkommnissen in RF Berichten</li>
            <li>Ergänzung der Hinweise auf Überschreitungen von 20.000/50.000 cGy*cm2 in RF Berichten</li>
            <li>Entfernung des Hinweises auf Phantomüberschreitungen bei NM</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.14c</h1>
        Release: 09.07.2024

        <h2>Neu</h2>
        <ul>
            <li>Monatsbericht V2 für MG, RF und XR</li>
            <li>Software "Domako (3rd Party)" und ETL hinzugefügt</li>
            <li>Warnung für Typänderung bei PET/CT ergänzt</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Textkorrektur im Monatsbericht CT für fehlende Zuordnungen</li>
            <li>Software "GE" zu "DoseWatch", "Guerbet" zu "Dose&Care" und "TQM" zu "TQM-Dose" umbenannt</li>
            <li>Topogramfilter erweitert. Gefiltert werden Protocol/Acq. Protocol = Topo|Scout|SCOUT|Planungsspirale & Acq. Type = Constant Angle Acquisition</li>
            <li>Fehlerhaft immer-leere Körperregion in RF-Excel behoben</li>
            <li>Anpassungen an Spaltennamen der TQM CT ETL. Das DRW-Mapping verweist auf Studienbeschreibung und Protokollname</li>
            <li>Anpassungen an Spaltennamen der TQM MG ETL. Das DRW-Mapping verweist auf Studienbeschreibung und Protokollname</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.14b1</h1>
        Release: 03.07.2024

        <h2>Updates</h2>
        <ul>
            <li>Fehlerbehebung eines Fehlers: Beim ignorieren der "Anzahl Serien ohne DRW obwohl nötig" wurde das System unbenutzbar</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.14b</h1>
        Release: 11.06.2024

        <h2>Updates</h2>
        <ul>
            <li>Formelkorrekturen in RF Exporten</li>
            <li>"CT Aufnahmeart" in CT-Exporten eingeblendet</li>
            <li>Gruppe "Nicht zugeordnet" auf der x-Achse der Plots im Monatsbericht V2 ergänzt</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.14</h1>
        Release: 09.06.2024
        <h2>Neu</h2>
        <ul>
            <li>Daten importe für TQM und GE für CT, MG und XR, sowie Mappingdaten</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Ergänzung eines neuen Spaltennamen für Teamplay-CT-Importe</li>
            <li>Ergänzung fehlender Formeln im Excel-Export</li>
            <li>Umbenennung "Acc.DFP" zu "DFP" in RF-Export</li>
        </ul>
        <h3>Formelanpassungen</h3>
        <ul>
            <li>Ergänzung eines fixen Filters für Topogramme, angewendet auf alle Formeln. Anpassung des existierenden Filters auf Monitorings</li>
            <li>Umbenennung der "relevanten Serien" zu "Serien mit DRW"</li>
            <li>Berechnung der relevanten Serien aus der Summe der Serien mit DRW und den Serien ohne DRW obwohl nötig</li>
            <li>Anpassung von Anteilswerten auf die neue Formel relevanter Serien</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.13b</h1>
        Release: 09.06.2024

        <h2>Updates</h2>
        <ul>
            <li>Korrektur der Platzierung der Signatur</li>
            <li>Textanpassungen in Monatsberichten</li>
            <li>Formatierungsanpassungen in Monatsberichten</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.13</h1>
        Release: 01.05.2024

        <h2>Neu</h2>
        <ul>
            <li>Max. CTDI für Kopf und Körper Serien bei CT</li>
            <li>Die Appendix-Tabelle im CT Monatsbericht V2 wurde um weitere statistische Werte ergänzt</li>
            <li>Der Excel-Export für Jahresberichte erfolgt aus Performance-Gründen nur noch auf Anforderung.</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Korrektur von Schreibfehlern</li>
            <li>Textanpassungen in Monatsberichten</li>
            <li>Formatierungsanpassungen in Monatsberichten</li>
            <li>Anpassung Achsenbeschriftung in CT Monatsbericht V2</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.12f</h1>
        Release: 13.04.2024

        <h2>Neu</h2>
        <ul>
            <li>Im Jahresbericht ist ab sofort eine Vorschau verfügbar</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Die Übersichtstabelle in Jahresberichten hat keine Spalte für Vorjahreswerte mehr, wenn dort keine Daten existieren</li>
            <li>Die Boxplots in Monatsberichten wird nur angehängt, wenn Grafiken mit Boxplot enthalten sind</li>
            <li>Fehlerbehebung in ETL XR Teamplay</li>
            <li>Fehlerbehebung in ETL CT+MG Guerbet</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.12e</h1>
        Release: 07.03.2024

        <h2>Neu</h2>
        <ul>
            <li>Der Monatsbericht V2 enthält ab sofort die Erklärung für Boxplots auf der letzten Seite</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.12d</h1>
        Release: 24.02.2024

        <h2>Updates</h2>
        <ul>
            <li>Manuelle Eingaben werden in der Anhang-Tabele des Monatsberichts ignoriert, da diese nicht einzelnen Protokollen zugeordnet werden können.</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.12c</h1>
        Release: 22.02.2024

        <h2>Updates</h2>
        <ul>
            <li>Fehlerbehebung in der Datenverarbeitung für Guerbet CT und MG, DoseM RF und XR</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.12b</h1>
        Release: 15.02.2024

        <h2>Updates</h2>
        <ul>
            <li>Fehlerbehebung MG-Import</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.12</h1>
        Release: 11.02.2024

        <h2>Neu</h2>
        <ul>
            <li>Neue Felder in der Datenbank und im Datenimport ergänzt</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Datenimport stabilisiert: Fehlerhafte Werte im Datenimport führen nicht mehr zum Abbruch des Imports.
                Fehlerhafte Werte werden übersprungen und eine Warnung wird im Datensatz gespeichert.
                Zusätzlich wird eine Warnung in der App angezeigt.
            </li>
            <li>Fehlermeldungen verkürzt und verständlicher gemacht</li>
            <li>Spaltennamen im Export auf Deutsch geändert</li>

            <li>Probleme beim Ablegen der Dateien im Uploadfenster behoben</li>
            <li>Fehler bei der Verwendung von Unterstrichen im Monatsbericht bzw. in Aliasnamen behoben</li>

            <li>Probleme in den Tabellen der Monatsberichte durch leere Protokolle behoben</li>
            <li>Stabilisierung der Monatsberichte: Bei fehlerhaften Bildern oder Tabellen kann das PDF jetzt ohne
                diese Inhalte erzeugt werden.
            </li>
            <li>Anzahl der Protokolle und BodyRegions in den Bildern der Monatsberichte auf 20 begrenzt</li>
        </ul>
        <Divider style={{marginTop: 96}}/>

        <h1>v2.0.11b</h1>
        Release: 12.01.2024

        <h2>Updates</h2>
        <ul>
            <li>Korrektur fehlerhafter Formeln im Excelexport</li>
            <li>Korrektur fehlerhafter y-Achsen-Werte in Grafiken des Monatsberichts</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.11a</h1>
        Release: 12.01.2024

        <h2>Updates</h2>
        <ul>
            <li>Behebung eines Fehlers welcher den Excelexport von RF Daten verhindert hat</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.11</h1>
        Release: 07.01.2024

        <h2>Neu</h2>
        <ul>
            <li>neuer Monatsbericht mit Grafiken und Tabellen für CT</li>
            <li>der neue Monatsbericht enthält Kontaktdaten des MPE, wenn diese im Nutzeraccount des MPE hinterlegt
                sind
            </li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Die gewohnten Monatsberichte für alle Gerätetypen sind unter "Monatsbericht V1" zu finden</li>
            <li>Maximaler CTDI in CT Monatsbericht ergänzt.</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.10d</h1>
        Release: 19.11.2023

        <h2>Neu</h2>
        <ul>
            <li>{"Tabellenblatt für 'Gesamtdosis > 20000/50000 cGy cm2' im Excelexport für RF ergänzt"}</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Fehlerbehebung zu fehlenden Kommentaren bei DoseM importen</li>
            <li>Quick-Action Buttons wurden nach links verschoben</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.10c</h1>
        Release: 04.11.2023

        <h2>Neu</h2>
        <ul>
            <li>Unterstützung von DoseM XR im Upload</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Maximaler CTDI in CT Monatsbericht ergänzt.</li>
            <li>Ergänzung von "Durchleuchtung" bzw. "Intervention" im RF Monatsbericht</li>
            <li>Fehlerbehebung in der Klassifizierungen des Patient als Erwachsener</li>
            <li>{"Fehlerbehebung im CT Excelexport für CTDI > 80/120 mGy"}</li>
            <li>Fehlerbehebung bei der automatischen Encoding-Erkennung</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.10b</h1>
        Release: 29.09.2023

        <ul>
            <li>Update des Jahresbericht auf neue Formeln und erweiterte Excel-Exporte</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.10a</h1>
        Release: 28.09.2023

        <h2>Updates</h2>
        <ul>
            <li>Behebung von Problemen bei welchen die Kommentierungsquote in den Berichten fehlt wenn nur manuelle
                Eingaben vorliegen.
            </li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.10</h1>
        Release: 24.09.2023

        <h2>Neu</h2>
        <ul>
            <li>Neuer Workflow für Berichte: Ein extra Schritt ermöglicht das Editieren der Werte über eine manuelle
                Eingabe
            </li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Der maximale Referenzwert wird jetzt für jeden Gerätetyp im Bericht erwähnt</li>
            <li>Überschreitungen der Meldeschwelle 20000/50000 cGy*cm² im RF-Bericht ergänzt</li>
            <li>{'Korrekturen an der Klassifizierung falscher Kindzuordnungen: Die Grenze für Kinder ist jetzt < 180 Monate und < 56kg'}</li>
        </ul>

        <h2>Bekannte Bugs</h2>
        Die folgenden Probleme sind bekannt, an einer Lösung wird gearbeitet:
        <ul>
            <li>Unterschriche in Geräte Namen oder Aliassen führen zu Formatierungsfehlern in Berichten. Dieses Problem
                kann umgangen werden, indem keine Unterstriche verwendet werden.
            </li>
            <li>Die Erzeugung von Jahresberichten funktioniert abhängig vom Gerätetyp nur eingeschränkt oder garnicht.
            </li>
        </ul>


        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.9d</h1>
        Release: 11.09.2023

        <h2>Updates</h2>
        <ul>
            <li>Fehlerbehebung Export PET/CT</li>
        </ul>


        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.9c</h1>
        Release: 03.09.2023

        <h2>Updates</h2>
        <ul>
            <li>Erstellungsdatum in Berichten ergänzt</li>
            <li>Fehlerbehebung welcher das System bei leerem CTDI unnutzbar macht</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.9b</h1>
        Release: 10.08.2023

        <h2>Updates</h2>
        <ul>
            <li>{'Fehlerkorrektur in Berechnung von RF Sereien mit DFP > 20.000/50.000 cGy*cm2'}</li>
            <li>Import der Scanlänge bei Domako CTs</li>
            <li>Kinder- & Phantomzuordnungen in Excel ausgeblendet wenn nicht benötigt</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.9</h1>
        Release: 09.08.2023

        <h2>Neu</h2>
        <ul>
            <li>Rechtschreibprüfung durch lokalen LanguageTool-Server</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Dosismanagementsysteme "Dose&Care" und "RadCentre" hinzugefügt</li>
            <li>PET zu PET/CT umbenannt</li>
            <li>Überschreitungen DFP Grenzwerts von 20000 und 50000 in manuellen Eingaben ergänzt</li>
            <li>{'CT Excelexport enthält ab jetzt Monitoringserien in den Mappen "CTDI>80mGy" und "CTDI>120mGy"'}</li>
            <li>Instituts- und Geräteinfo in Excel ergänzt</li>
            <li>Support Mail auf support@itp-consult.de aktualisert</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.8b</h1>
        Release: 18.07.2023

        <h2>Updates</h2>
        <ul>
            <li>Bugfixes zur Stabilisierung des Systems</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.8</h1>
        Release: 17.07.2023
        <h2>Neu</h2>
        <ul>
            <li>RF-Formeln auf Untersuchungsebene umgestellt</li>
            <li>RF-Excelexport auf Untersuchungsebene umgestellt</li>
            <li>Formelberechnungen überarbeitet</li>
            <li>Manuelle Eingaben überschreiben berechnete Werte partiell, dort wo manuelle Eingaben gemacht wurden</li>
            <li>'Max. Überschreitung' zu manuellen Eingaben hinzugefügt</li>
            <li>Warnung im Tabellen-Footer wenn Filter gesetzt sind</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Messwert 'INJ' zu 'Aktivität' umbenannt</li>
            <li>Serientab in den Gerätedetails ausgeblendet</li>
            <li>Pflichtfelder in manuellen Eingaben entfernt</li>
            <li>Monatsberichte auf ganze Monate eingegrenzt</li>
            <li>Quickaction-Buttons am rechten Tabellenrand fixiert</li>
            <li>Tabs für falsche Kind- & Phantomzuordnung im Excelexport hinzugefügt</li>
            <li>Institution und Gerätename im Export hinzugefügt</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.7b</h1>
        Release: 12.06.2023
        <h2>Update</h2>
        <ul>
            <li>Fehler in der BMI Berechnung bei Größe = 0 abgefangen</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.7</h1>
        Release: 24.05.2023
        <h2>Neu</h2>
        <ul>
            <li>Button für Seitenumbruch im Editor</li>
        </ul>

        <h2>Updates</h2>
        <ul>
            <li>Behebung fehlender Überschreitungen bei XR und RF bei automatischem Upload</li>
            <li>Patienten sind ab dem 17 Lebensjahr als Erwachsen zu werten</li>
            <li>Teamplay und Domako Upload mit mehr als 6 Millisekundenstellen unterstützt</li>
            <li>Fehlende Werte durch Anzeigefehler in den Uploadtabellen angepast</li>
            <li>DLP-Überschreitungen werden nicht mehr für die Kommentierungsquote beachtet</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.6</h1>
        Release: 11.05.2023
        <h2>Updates</h2>
        <ul>
            <li>Fehler im Domakoimport behoben</li>
            <li>Fehlende Berechtigung zum Löschen von Uploads angepasst</li>
            <li>Fehler im PDF Export behoben ("~"-Zeichen in Leerzeilen)</li>
            <li>Breadcrumbs angepasst</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.5</h1>
        Release: 04.05.2023
        <h2>Updates</h2>
        <ul>
            <li>Kommentare für Domako Uploads werden wieder nur aus Spalte "Kommentare von Benutzern" übernommen</li>
            <li>Probleme beim Import von Kommentaren für Domako Upload wurden behoben</li>
            <li>Geänderte Datumsformate in Teamplay werden unterstützt</li>
            <li>Formatierungsfelher in Latex bei verwendung von Anführungszeichen wurden behoben</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.4</h1>
        Release: 22.04.2023
        <h2>Updates</h2>
        <ul>
            <li>Kommentare für Domako Uploads werden jetzt aus Spalte "Kommentar" und "Kommentare von Benutzern"
                übernommen und zusammengefügt
            </li>
            <li>Die Berechnung der Anzahl der Überschreitungen der absoluten CTDI Meldeschwellen wurde korrigiert</li>
            <li>Das neue Datumsformat für die StudyTime für Teamplay NM wird unterstützt</li>
            <li>Der Text zu fehlerhaften Phantomzuordnungen wurde aus den RF Berichten entfernt</li>
            <li>Anpassung der Dateinamen für Berichte und Excel-Exporte</li>
            <li>Der BreadCrumb in der Gerätedetailansicht verweist jetzt auf den Kunden</li>
            <li>Die Anzahl der Nachkommastellen im Verlauf der Monatsberichtswerte ist an die Definition der Berichte
                angeglichen
            </li>
            <li>Fehlermeldungen über den "Bug melden"-Button werden standardmäßig im CC an den MPE-Mailverteiler
                gesendet
            </li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.3</h1>
        Release: 12.04.2023
        <h2>Updates</h2>
        <ul>
            <li>Korrektur der Einordnung der CTDI Überschreitung im Monatsbericht</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.2</h1>
        Release: 07.04.2023
        <h2>Neu</h2>
        <ul>
            <li>Upload-Liste enthält jetzt auch automatische Uploads</li>
            <li>Upload für DoseM RF</li>
        </ul>
        <h2>Updates</h2>
        <ul>
            <li>Neuer Text der Monatsberichte für MG, NM, PET, RF, XR</li>
            <li>Aktualisierter Text des Monatsberichtes für CT</li>
            <li>Anpassungen am Reportdesign</li>
            <li>Anpassungen an Dateibenamungen für Exporte</li>
            <li>Verbesserungen im manuellen Import</li>
            <li>Suchfunktion in Bearbeitungsansicht beachtet keine Groß- und Kleinschreibung</li>
            <li>Gerätelisten zeigen die vollständige Adresse</li>
            <li>Domako Uploads unterstützen UTF-8 & CP1252 Format</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.1</h1>
        Release: 23.03.2023
        <h2>Neu</h2>
        <ul>
            <li>Monatsberichte als PDF</li>
        </ul>
        <h2>Updates</h2>
        <ul>
            <li>Neuer Text für CT-Monatsberichte</li>
            <li>Verbesserungen im manuellen Import</li>
            <li>Aktivitäts-Popups nach unten links verschoben</li>
            <li>Filter der Geräteliste in den Kundendetails werden nicht mehr gespeichert</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.0 - GoLive</h1>
        Release: 08.03.2023
        <h2>Neu</h2>
        <ul>
            <li>Datenimport Teamplay: RF, NUK, PET-CT</li>
            <li>Datenimport Domako: RF</li>
            <li>Einführung der Referenzwerte für ScanLength für CT</li>
            <li>Korrekturen im manuellen Upload</li>
        </ul>
        <h2>Updates</h2>
        <ul>
            <li>Korrekturen in den Datenimporten</li>
            <li>Optimierung der Jahresberichtserzeugung</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.beta2</h1>
        Release: 12.02.2023
        <h2>Neu</h2>
        <ul>
            <li>Layout-Update</li>
            <li>PDF-Jahresbericht mit Editor</li>
            <li>Manueller Upload für das gesamte Jahr möglich</li>
            <li>vCard-Import für Kontakte</li>
            <li>DoseM ist als Sorfware im System verfügbar</li>
            <li>Zusätzliche Seiten im Excel-Export mit Auswertungen</li>
            <li>Formeln mit Auswertungen im Excel Export</li>
            <li>Verlaufsübersicht der letzten 12 Monate im Gerät hinzugefügt</li>
        </ul>

        <Divider style={{marginTop: 96}}/>
        <h1>v2.0.beta1</h1>
        Release: 28.11.2022
        <h2>Neu</h2>
        <ul>
            <li>Monatsbericht</li>
            <li>Datenimport für Teamplay & Domako</li>
            <li>Manuelle Eingaben</li>
        </ul>
    </>
)
